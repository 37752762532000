:root {

    .dbk-image-row {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .modal-header .mc3-modal-close {
        margin-top: -0.05rem;
        margin-right: -0.25rem;
        font-size: 1.3rem;
        line-height: 1.2rem;
    }

    button.mc3-modal-close {
        padding: 0;
        cursor: pointer;
        background: 0 0;
        border: 0;
        -webkit-appearance: none;
    }

    .mc3-modal-close {
        float: right;
        font-size: 1.8rem;
        transition: color linear 0.2s;
        font-weight: 200;
        text-shadow: none;
        line-height: inherit;
        color: #8c8c8c;
        color: var(--clr-close-color--normal, #8c8c8c);
    }

    .modal-header .mc3-modal-close cds-icon {
        fill: #8c8c8c;
        fill: var(--clr-modal-close-color, #8c8c8c);
        height: 1.2rem;
        width: 1.2rem;
    }

    .mc3-filter-control {
        display: flex;
        justify-content: flex-end;

        .clr-form-control {
            margin: 0;
        }
    }

    .language-selection {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        .clr-form-control {
            margin-top: 0 !important;
        }
    }

    .datagrid-host {
        .datagrid-action-bar {
            button {
                padding: inherit !important;
            }
        }

        .column-switch-wrapper .column-toggle--action {
            padding: 0rem .3rem !important;
        }
    }

    .dbk-combo-box {
        div.clr-control-container {
            div.clr-combobox-wrapper {
                span {
                    overflow-wrap: anywhere;
                }
            }
        }
    }
    .dbk-tooltip {
        opacity: unset !important;
        z-index: 0 !important;
    }

    .dbk-hidden {
        display: none !important;
    }
.dbk-multi-select {
    position: relative;
    top: 11.5px;
    min-width: 7rem;
    .multiselect-dropdown .dropdown-btn  .selected-item-container .selected-item {
        max-width: none !important;
    }

    .multiselect-dropdown .dropdown-btn {
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #6a7a81 !important;
    }

    .multiselect-item-checkbox input[type=checkbox] + div:after {
        box-sizing: content-box !important;
        left: 2px !important;
        height: 2px !important;
    }
    .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
        right: -8px !important;
    }

    .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
        margin-right: 5px;
    }
}
.dbk-text-area {
    .clr-control-container {
        width: 100% !important;
        height: 100% !important;
    }

    clr-textarea-container {
        margin-top: .25rem !important;
        height: 100% !important;
    }

    .clr-textarea-wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    textarea {
        width: 100% !important;
        min-height: 200px;
    }
}
}

.clr-w-100,
.w-100 {
  width: 100%;
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
  }
  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }

  .dbc-flex {
      display: flex !important;
  }

  .dbc-flex-ai-cen {
    align-items: center !important;
}

.dbc-flex-ai-end {
    align-items: end !important;
}

.support-title {
    margin-top: 0 !important;
}

.support-text-content {
    margin: 5px 0 !important;
}

.support-sub-title {
    margin: 0;
}

.support-content {
    margin-top: 8px !important;
}

.header-tenant-sec::before {
    content: none !important;
}